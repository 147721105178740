import { get, put } from '@/untils/js/axios.js'

// 兑换
export const exchange = params => put('/cdkey/exchange', params)

// 兑换列表
export const exchange_list = params => get('/cdkey', params)


// 重新审核
export const again = (id, params) => put(`/rebate/user/${id}`, params)