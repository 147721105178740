<template>
    <van-overlay :show="show" z-index="999" @click="close">
        <div class="wrapper">
            <div class="swap" @click.stop v-if="prizeInfo.type != 2">
                <div class="swap_title">{{swapSuccess ? '恭喜你': '刮刮乐兑奖'}}</div>
                <div class="swap_tip">
                    <span v-if="!swapSuccess">专属您的礼物 100%中奖</span>
                    <span v-if="swapSuccess && prizeInfo.type == 1">获得'{{prizeInfo.gift.title.substring(0,10)}}'奖品</span>
                    <span v-if="swapSuccess && prizeInfo.type == 0">获得
                        <span v-if="prizeInfo.coupon.discount == 1">商品代金券</span>
                        <span v-if="prizeInfo.coupon.discount == 2">商品满减券</span>
                        <span v-if="prizeInfo.coupon.discount == 3">商品折扣券</span>
                    </span>
                </div>
                <div v-if="!swapSuccess">
                    <div class="swap_int">
                        <p>您的兑奖码</p>
                        <input type="text" oninput="value=value.replace(/[^\w\.\/]/ig,'').toUpperCase()" placeholder="请输入您的兑奖码" v-model="code">
                    </div>
                    <div class="swap_btn" @click="handleExchange">立即兑奖</div>
                </div>
                <div class="swap_gift" v-if="swapSuccess && prizeInfo.type == 1">
                    <img v-if="prizeInfo.gift.pic" :src="prizeInfo.gift.pic" alt="">
                    <p>您的礼品将随mooby订单随单派送哦～</p>
                </div>
                <div class="swap_coupon" v-if="swapSuccess && prizeInfo.type == 0">
                    <div class="coupon_bg">
                        <div class="coupon-l">
                            <div class="discount2" v-if="prizeInfo.coupon.discount == 1">
                                <p>${{prizeInfo.coupon.coupon_money}}</p>
                            </div>
                            <div class="discount1" v-if="prizeInfo.coupon.discount == 2">
                                <p>$<span>{{prizeInfo.coupon.reduce}}</span></p>
                                <p>off ${{prizeInfo.coupon.full_money}}<br>Coupon</p>
                            </div>
                            <div class="discount1" v-if="prizeInfo.coupon.discount == 3">
                                <p><span>{{prizeInfo.coupon.coupon_money}}%</span></p>
                                <p>off <br>Coupon</p>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="coupon-r">×<span>1</span></div>
                    </div>
                    <p>优惠券已放入你的账户中可在“我的-优惠券”查看</p>
                </div>
            </div>
            <div class="rebate" @click.stop v-if="prizeInfo.type == 2">
                <div class="rebate_title">恭喜你</div>
                <div class="rebate_tip">获得订单金额返现福利，补充订单信息即刻返现～</div>
                <div class="rebate_int">
                    <p>下单平台</p>
                    <input type="text" placeholder="请输入您下单平台名称" v-model="apply_channel">
                </div>
                <div class="rebate_int">
                    <p>手机号</p>
                    <input type="text" placeholder="请输入您的下单手机号" v-model="apply_phone">
                </div>
                <div class="rebate_int">
                    <p>订单金额</p>
                    <input type="text" placeholder="请输入您的订单金额" v-model="apply_amount">
                </div>
                <div class="rebate_btn" @click="handleRebate">提交返现</div>
            </div>
        </div>
    </van-overlay>
</template>
<script>
import { exchange } from '@/api/zh/scratch.js'
export default {
    props:['show'],
    data() {
        return {
            code: '',
            apply_channel: '',
            apply_phone: '',
            apply_amount: '',
            swapSuccess: false,
            prizeInfo: {
                gift:'',
                rebate:'',
                coupon:'',
            }
        }
    },
    methods: {
        close() {
            this.$emit('fatherMethod');
            this.$emit('update:show', false)
            this.swapSuccess = false
            this.code = ''
            this.apply_channel = ''
            this.apply_phone = ''
            this.apply_amount = ''
        },
        // 兑换
        handleExchange() {
            if(this.code == '') {
                return this.$notify({type:'warning',message:'请输入兑换码'})
            }
            let params =  {
                code: this.code
            }
            this.$store.state.load = true;
            exchange(params).then(res => {
                if(res) {
                    this.prizeInfo = res.data
                    this.swapSuccess = true
                }
            }).finally(() => {
                this.$store.state.load = false;
            })
        },
        handleRebate() {
            this.$store.state.load = true;
            let params =  {
                code: this.code,
                apply_channel: this.apply_channel,
                apply_phone: this.apply_phone,
                apply_amount: this.apply_amount,
            }
            exchange(params).then(res => {
                if(res) {
                    this.$notify({type:'success',message:'提交成功，需审核，请耐心等待~'})
                    this.close()
                }
            }).finally(() => {
                this.$store.state.load = false;
            })
        }
    }
};
</script>
<style scoped lang='less'>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .swap {
    width: 286px;
    height: 357px;
    background: url('../../../assets/scratch/swap-bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 74px 32px 0 29px;
    color: #fff;
    .swap_title {
        font-size: 28px;
    }
    .swap_tip {
        font-size: 14px;
    }
    .swap_int {
        font-size: 14px;
        margin-top: 31px;
        input {
            width: 220px;
            height: 40px;
            border-radius: 8px;
            border:none;
            margin-top: 4px;
            padding-left: 12px;
            color: #333;
            &::placeholder {
            color: #999;
            font-size: 12px;
            }
        }
    }
    .swap_gift {
        display: flex;
        flex-flow: column;
        align-items: center;
        img {
            display: inline-block;
            width: 140px;
            height: 140px;
            border-radius: 16px;
            background: #fff;
            margin: 12px 0;
        }
        p {
            width: 182px;
            font-size: 14px;
            letter-spacing: 1;
            text-align: center;
        }
    }
    .swap_coupon {
        display: flex;
        flex-flow: column;
        align-items: center;
        .coupon_bg {
            margin: 33px 0 16px;
            width: 216px;
            height: 75px;
            background: url('../../../assets/scratch/coupon-bg.png') no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 11px;
            position: relative;
            .coupon-l {
                .discount1 {
                    width: 157px;
                    display: flex;
                    align-items: center;
                    &>p:nth-child(1) {
                        color: #FF6842;
                        font-size: 20px;
                        font-weight: bold;
                        span {
                            font-size: 42px;
                        }
                    }
                    &>p:nth-child(2) {
                        margin-left: 8px;
                        font-size: 13px;
                        letter-spacing: 1px;
                        color: #000;
                    }
                }
                .discount2 {
                    width: 157px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &>p:nth-child(1) {
                        color: #FF6842;
                        font-size: 42px;
                        font-weight: bold;
                    }
                }
            }
            .line {
                position: absolute;
                top: 50%;
                right: 48px;
                transform: translateY(-50%);
                width: 1px;
                height: 60px;
                border-left: 1px dashed #ED1821;
            }
            .coupon-r{
                color: #FF6842;
                font-weight: bold;
                span {
                    font-size: 22px;
                }
            }
        }
        &>p {
            width: 182px;
            font-size: 14px;
            letter-spacing: 1;
            text-align: center;
        }
    }
    .swap_btn {
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: url('../../../assets/scratch/swap_btn.png') no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #B64C1C;
        margin-top: 36px;
    }
  }
  .rebate {
        width: 287px;
        height: 451px;
        background: url('../../../assets/scratch/rebate-bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 60px 32px 0 29px;
        color: #fff;
        .rebate_title {
        font-size: 28px;
        }
        .rebate_tip {
        font-size: 14px;
        }
        .rebate_int {
        font-size: 14px;
        margin-top: 11px;
        input {
            width: 220px;
            height: 40px;
            border-radius: 8px;
            border:none;
            margin-top: 4px;
            padding-left: 12px;
            color: #333;
            &::placeholder {
            color: #999;
            font-size: 12px;
            }
        }
    }
    .rebate_btn {
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: url('../../../assets/scratch/swap_btn.png') no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #B64C1C;
        margin-top: 32px;
    }
  }
}
</style>