<template>
  <section class="m-account m-scroll" ref="scroll" @scroll="handleScroll($event)">

      <!-- 头部个人信息 -->
      <div class="info">
        <router-link :to="{path: '/zh/setting', query:{avatar:user.pic_url}}">
          <img src="@/assets/account/icon_shezhi@2x.png" alt="setting">
        </router-link>

        <div class="user m-padding">
          <img :src="user.pic_url == '' ? $store.state.avatar :  user.pic_url" alt="user">
          <div>
            <p class="van-ellipsis">{{user.nickname}}</p>
            <p class="van-ellipsis">{{user.phone == '' ? user.email : user.phone}}</p>
          </div>
        </div>
        <div class="user-info ">
          <router-link to="/zh/followed/list"  class="user-after">
            <p>{{myTotal.follow}}</p>
            <p>关注</p>
          </router-link>
          <router-link to="/zh/favorite">
            <p>{{myTotal.collect}}</p>
            <p>收藏</p>
          </router-link>
          <router-link to="/zh/record">
            <p>{{myTotal.userFoot}}</p>
            <p>足迹</p>
          </router-link>
          <router-link to="/zh/coupon">
            <p>{{myTotal.coupon}}</p>
            <p>优惠券</p>
          </router-link>
        </div>
      </div>

      <!-- 钱包 -->
      <div class="wallet">
        <div>
          <p>${{money.withdrawal_amount}}</p>
          <p>充值余额</p>
        </div>
        <div>
          <p>${{money.non_withdrawal_amount}}</p>
          <p>活动余额</p>
        </div>
        <router-link to="/zh/wallet">
          <img src="@/assets/account/icon_qiambao@2x.png" alt="img">
          <p>我的钱包</p>
        </router-link>
      </div>
      <!-- 刮刮乐 -->
      <div class="scratch" @click="scratchShow = true">
        <img src="@/assets/scratch/scratch_bg.png" alt="">
      </div>
      <m-scratch :show.sync="scratchShow"></m-scratch>

      <!-- 订单 -->
      <div class="order-wrap">
        <index-cell title="我的订单" value="查看全部" to="/zh/order/0?back=account"></index-cell>
        <div class="order-items">
          <router-link to="/zh/order/1?back=account" class="item">
            <img src="@/assets/account/icon_daifukuan@2x.png" alt="img">
            <span>待付款</span>
          </router-link>
          <router-link to="/zh/order/2?back=account" class="item">
            <img src="@/assets/account/icon_daifahuo@2x.png" alt="img">
            <span>待发货</span>
          </router-link>
          <router-link to="/zh/order/3?back=account" class="item">
            <img src="@/assets/account/icon_daishouhuo@2x.png" alt="img">
            <span>待收货</span>
          </router-link>
          <router-link to="/zh/comment/list" class="item">
            <img src="@/assets/account/icon_pingjia@2x.png" alt="img">
            <span>评价</span>
          </router-link>
          <router-link to="/zh/order/4?back=account" class="item">
            <img src="@/assets/account/icon_tuihuo@2x.png" alt="img">
            <span>退货/退款</span>
          </router-link>
        </div>
        <!-- <div class="detail">
          <img src="@/assets/fork-imgs/1591779685230830jvb.jpg" alt="goods">
          <div class="detail-info">
            <p><img src="@/assets/account/icon_yunsu@2x.png" alt="icon"><span>运输中</span></p>
            <p class="van-multi-ellipsis--l2">预计到仓时间2020.6.12，预计发货时间2020.6.14， 发货后3-5个工作日送达</p>
          </div>
        </div> -->
        <!-- 2022.3.5 -->
        <!-- <div class="active_min" v-if="loveShow">
            <img class="love" src="@/assets/account/love.png" alt="">
            <img class="btn" @click="toPrice()" src="@/assets/account/button-min.png" alt="">
        </div> -->
      </div>
      

      <!-- 服务 -->
      <div class="service-wrap">
        <index-cell title="我的服务"></index-cell>
        <div class="service-items">
          <router-link to="/zh/address" class="item">
            <img src="@/assets/account/icon_dizhiguanli@2x.png" alt="img">
            <span>地址管理</span>
          </router-link>
          <router-link :to="`/zh/merchant/application?s=${applicationStatus}&shop_type=${shop_type}&shop_id=${shop_id}`" class="item" v-if="$store.state.isMiniProgram != true">
            <img src="@/assets/account/icon_ruzhupingtai@2x.png" alt="img">
            <span>入驻平台</span>
          </router-link>
          <router-link :to="`/zh/partner`" class="item" v-if="partnerStatus != 2 && $store.state.isMiniProgram != true">
            <img src="@/assets/account/icon_hehuoren@2x.png" alt="img">
            <span>成为合伙人</span>
          </router-link>
          <router-link :to="`/zh/wallet-agent`" class="item" v-else-if="partnerStatus == 2 && $store.state.isMiniProgram != true">
            <img src="@/assets/account/icon_hehuoren@2x.png" alt="img">
            <span>合伙人账户</span>
          </router-link>
          <!-- <router-link to="/zh/account" class="item">
            <img src="@/assets/account/icon_vip@2x.png" alt="img">
            <span>成为会员</span>
          </router-link> -->
          <router-link to="/zh/customer" class="item">
            <img src="@/assets/account/icon_guanfangkefu@2x.png" alt="img">
            <span>官方客服</span>
          </router-link>
          <router-link to="/zh/suggest" class="item">
            <img src="@/assets/account/icon_pingtaifankui@2x.png" alt="img">
            <span>平台反馈</span>
          </router-link>
          <router-link to="/zh/search_order" class="item" v-if="guest">
            <img src="@/assets/account/phone.png" alt="img">
            <span>游客查单</span>
          </router-link>
          <router-link to="/zh/scratch" class="item">
            <img src="@/assets/scratch/ggl.png" alt="img">
            <span>刮刮乐</span>
          </router-link>
        </div>
      </div>

       <!-- 为您推荐 -->
      <m-divider title="为您推荐" class="recomnd"></m-divider>
      <goods-columns @cartClick="handleCart" :data="remList" :columns="2"></goods-columns>
      <p class="search-no" v-if="noMore">无更多数据</p>

      <!-- 多次加载提示 -->
      <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

      <footer-bar></footer-bar>

      <!-- 选择SKU 加入购物车 -->
      <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId='goodsId'></m-sku>

       <!-- 加入购物车动画 -->
      <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

  </section>
</template>

<script>
import IndexCell    from '@/components/zh/index-cell.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MDivider     from '@/components/zh/m-divider.vue'
import FooterBar    from '@/components/zh/footer-bar.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import MScratch     from './m-scratch.vue'

import { checkPartner } from '@/api/zh/partner.js'
import { getAccountRem, shop_status, getAccountInfo, wallet, my_total, isGuest, mooby_luck_goods } from '@/api/zh/mine.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
    mixins: [ MixinScroll ],
    name:'Account',
    components:{ IndexCell, GoodsColumns, MDivider, FooterBar, MSku, MAnimate, MScratch },
    data(){
      return {
        lucky_status: false,
        skuModal:false,    // 控制显示 选择sku加入购物车面板
        loading:false,
        islock:false,     // 防止多次加载
        start:false,      // 是否开启加入购物车动画
        start_dom:null,   // 开启动画的起点DOM 
        page: 1,
        remList: [],
        noMore: false,
        goodsId: '',
        partnerStatus: 0,
        applicationStatus: -1,
        shop_type: 0,
        shop_id: 1,
        money: { amount: '0.00',  total_amount: '0.00' },
        user: { pic_url: '', nickname: 'MoobyYoho', phone: '', email: '' },
        
        // 关注 收藏 足迹 优惠券 
        myTotal: { coupon: 0, userFoot: 0, collect: 0, follow: 0 },
        guest: false,
        loveShow: false,
        scratchShow: false,
      }
    },
    methods: {
      // 判断是否有活动奖品
      toPrice() {
        this.$router.push({path:'/zh/mission', query: {uid: this.user.id}})
      },
      // 内容滑动事件
      handleScroll(e){
        if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50 && !this.noMore){
          if(!this.islock){
            e.preventDefault()
            e.stopPropagation()
            this.page++
            this.loading = true
            this.islock = true
            this.getAccountRemHandle()
          }          
        }
      },
      // 商品列表购物车点击事件
      handleCart(goods,e){
        this.goodsId = goods
        this.skuModal  = true
        this.start_dom = e 
      },
      // sku 弹窗加入购物车成功回调
      handleCartSuccess(){
        this.skuModal = false
        let timer = setTimeout( () => {
          this.start  = true
          clearTimeout(timer)
        },300)
      },
      // 加入购物车动画结束
      handleCartEnd(){
        this.start    = false
      },
      getAccountRemHandle() {
        // 个人中心为您推荐
        getAccountRem({page: this.page}).then(res => {
          this.loading  = false
          this.islock   = false
          if(res) {
            this.remList = this.remList.concat(res.data.data)
          }else{
            this.noMore = true
          }
        })
      },

      // 获取 入驻平台状态
      getShop() {
        shop_status().then(res => {
          if(res) {
            this.applicationStatus = res.status
            if(res.status == 1) {
              this.shop_id = res.shops_id
              this.shop_type = res.shops_type
            }
          }
        })
      },
      getAccountInfoHandle() {
        // 个人中心 信息
        getAccountInfo().then(res => {
          if(res) {
            this.user = res.data
            // let reg =/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
            // if(reg.test(this.user.phone)) {
            //   this.loveShow = false
            // } else {
            //   this.loveShow = true
            // }
            localStorage.setItem('setPersonal',JSON.stringify(res.data))
          }
        })
      },
      checkPartnerHandle() {
        // 判断是否已经成为合伙人
        checkPartner().then(res => {
          if(res) {
            // status  审核状态：0未申请 1审核中；2审核通过；3审核未通过
            this.partnerStatus = res.status
          }
        })
      },


      // 获取钱包相关
      getWallet() {
        wallet().then(res => {
            if(res) {
              this.money = res.data
            }
        })
      },

      // 获取 统计
      getTotal() {
        my_total().then(res => {
          if(res) {
            this.myTotal = res.data
          }
        })
      },

      isGuestHandle() {
        // 是否市游客
        isGuest().then(res => {
          if(res) {
            this.guest = res.status == 1 ? true : false
          }
        })
      },
      getLuckGoods() {
        mooby_luck_goods().then(res => {  
            this.lucky_status = res ? true : false 
        })
      },
    },
    activated() {
      this.getAccountInfoHandle()
      this.getWallet()
      this.getTotal()
      this.getShop()
      this.checkPartnerHandle()
      this.isGuestHandle()
      // this.getLuckGoods()
    },
    created() {
      // this.getLuckGoods()
      this.getAccountInfoHandle()
      this.getAccountRemHandle()
      this.getWallet()
      this.getTotal()
      this.getShop()
      this.checkPartnerHandle()
    }
}
</script>

<style lang="less" scoped>
.search-no {
  line-height:100px;
  text-align:center;
  font-size:12px;
  color:#888;
}
.active_min {
  padding: 25px  17px 0;
  width: 100%;
  height: 75px;
  position: relative;
  .love {
    position: absolute;
    right: 4.66667vw;
    top: -2.33333vw;
    width: 64px;
    height: 80px;
  }
  .btn {
    position: absolute;
    display: block;
    width: 343px;
    // margin: auto;
  }
}
.scratch {
  width: 359px;
  height: 80px;
  margin:0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
@import './account.less';
</style>